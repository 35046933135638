<template>
  <div class="view">
    <div class="title">重置密码</div>
    <van-form @submit="toLoginb">
      <van-cell-group inset>
        <van-field
          v-model="formObjb.name"
          name="name"
          label="用户名"
          placeholder="请输入用户名"
          :rules="[{ required: true, message: '请输入用户名' }]"
        />
        <van-field
          v-model="formObjb.phone"
          name="phone"
          label="手机号"
          placeholder="请输入手机号"
		  :rules="[
          { required: true, message: '请输入手机号' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误' },
        ]"
        />
        <van-field
          v-model="formObjb.pws"
          name="yzm"
          label="验证码"
          maxlength="6"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '请输入验证码' }]"
        >
          <template #button>
            <van-button
              @click="getYzm"
              :loading="isLoading.getYzm"
              :loading-text="dsLoadingText"
              plain
              size="small"
              type="primary"
              >获取验证码</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="formObjb.phone"
          name="phone"
          label="新密码"
          placeholder="请输入新密码"
          :rules="[
            { required: true, message: '请输入密码' },
            {
              pattern: pwreg,
              message: '密码需为8-16位，必须包含：数字、大写字母、小写字母',
            },
          ]"
        />
        <van-field
          v-model="formObjb.phone"
          name="phone"
          label="确认密码"
          placeholder="请再次输入密码"
          :rules="[{ required: true, message: '请输入密码' }]"
        />
        <p class="mmts">密码需为8-16位，必须包含：数字、大写字母、小写字母</p>
      </van-cell-group>

      <div style="margin: 1.8rem 16px 0.4rem">
        <van-button
          :loading="isLoading.toLoginb"
          round
          block
          type="primary"
          native-type="submit"
          >确认重置</van-button
        >
        <van-button
          style="margin-top: 15px"
          round
          block
          @click="$router.push('/login')"
          native-type="submit"
          >返回登录</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, CellGroup, Button } from "vant";
export default {
  name: "login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      loginType: 1,
      // 账号登录
      formObj: {},
      // 验证码登录
      formObjb: {},
      isLoading: {},
      ds: null,
      dsLoadingText: "",
      pwreg: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,16}$/,
    };
  },
  methods: {
    // 账号密码登录
    toLogin(values) {
      this.isLoading.toLogin = true;
      console.log(this.$router.push("/"));
      return;
      this.$http
        .request("login", values)
        .then((res) => {
          this.isLoading.toLogin = false;
          console.log(res, "登录成功");
        })
        .catch(() => {
          this.isLoading.toLogin = false;
        });
    },
    // 获取验证码
    getYzm() {
      // 获取验证码？
      this.isLoading.getYzm = true;
      if (this.ds) {
        window.clearInterval(this.ds);
      }
      this.dsLoadingText = "请稍等...";
      // 成功
      // 1分钟有效期
      let num = 60;
      // this.dsLoadingText=num+"秒"
      this.ds = window.setInterval(() => {
        num--;
        if (num <= 0) {
          window.clearInterval(this.ds);
          this.dsLoadingText = "";
          this.isLoading.getYzm = false;
        }
        this.dsLoadingText = num + "秒";
      }, 1000);
    },
    // 重置--登录（重置成功应该就进行登录）
    toLoginb() {
      this.isLoading.toLoginb = true;
      console.log(values);
      this.$http
        .request("login", values)
        .then((res) => {
          this.isLoading.toLoginb = false;
          console.log(res, "登录成功");
        })
        .catch(() => {
          this.isLoading.toLoginb = false;
        });
    },
  },
  beforeUnmount() {
    if (this.ds) {
      window.clearInterval(this.ds);
    }
  },
};
</script>

<style scoped lang="scss">
.view {
}
.title {
  margin: 30px var(--van-cell-horizontal-padding) 20px;
  font-size: 18px;
  font-weight: 500;
  color: #272d3b;
  line-height: 25px;
}
.mmts {
  font-size: 12px;
  padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
  font-weight: 400;
  color: #9da7b1;
}
</style>
